import { useEffect, useState } from "react";
import Base64  from 'base-64';
import { Button, Grid, Container } from '@material-ui/core';
import {
  connectWallet,
  getCurrentWalletConnected,
  getTokenURI,
  getApproveState,
  approveToken,
  mintNFT,
  upgradeParts,
  getFreePlayNum,
  // mintNFT2,
} from "../util/interact.js";

const Minter = (props) => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [approveState, setApproveState] = useState(0)
  const [amount, setAmount] = useState("1");
  const [minted, setMinted] = useState("");
  const [attributes, setAttributes] = useState([])
  const [partslist, setPartslist] = useState([0,0,0,0,0,0,0,0])
  const [name, setName] = useState("")
  const [freePlayNum, setFreePlayNum] = useState(0)
  const [refresher, setRefresher] = useState(-1)

  const getItemList = (result) => {
    var items = result.split(",");
    var itemList = [];
    for (var i=0; i<8; i++) {
        let itemAttr = {
          name: "",
          base: "",
          suffix: "",
          postfix: "",
          level: 1
        }
        var item = items[i];
        itemAttr.name = item;
        if (item.indexOf('[') == 0) {
            itemAttr.suffix = item.split(']')[0].trim();
            itemAttr.suffix = itemAttr.suffix.replace('[','');
        }
        if (item.indexOf('of') > 0) {
            itemAttr.postfix = item.split('of')[1].trim();
        }
        itemAttr.base = item.replace('['+itemAttr.suffix+']', '').replace(itemAttr.postfix, '').replace('of', '').trim();
        itemAttr.postfix = itemAttr.postfix.split('+')[0].trim();
        itemList.push(itemAttr)
      }
      var postfix_count = {};
      for (var i=0; i<8; i++) {
        if (itemList[i].postfix == "") {
          continue;
        }
        if ( itemList[i].postfix in postfix_count) {
            postfix_count[itemList[i].postfix] += 1;
        } else {
            postfix_count[itemList[i].postfix] = 1;
        }
      }
      for (var i=0; i<8; i++) {
        if (itemList[i].postfix == "") {
          continue;
        }
        itemList[i].level = postfix_count[itemList[i].postfix]
      }
    return itemList;
  }
  const getAttributes = (baseStr, parameterName) => {
    var result = null,
        tmp = [];
    var items = baseStr.split("?")[1].split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return getItemList(result)
  }

  const updateInfo = async () => {
    var tokenURI = await getTokenURI(amount);
    tokenURI = Base64.decode(tokenURI.split(",")[1])
    var bagAttr = JSON.parse(tokenURI);
    setName(bagAttr.name);
    var attributes = getAttributes(bagAttr.animation_url, "base");
    setAttributes(attributes);
    var apstate = await getApproveState();
    setApproveState(apstate);
    var freePlayNum = await getFreePlayNum(amount);
    setFreePlayNum(freePlayNum);
    console.log("update");

  }

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    addWalletListener();
    await updateInfo();
  }, []);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  // const onMintPressed = async () => {
  //   const { success, status } = await mintNFT(url, name, description);
  //   setStatus(status);
  //   if (success) {
  //     setName("");
  //     setDescription("");
  //     setURL("");
  //   }
  // };


  const onMintBtnPressed = async () => {
    const { success, status } = await mintNFT();
    console.log(success, status);
    setStatus(status);
    if (success) {
    }
  };
  const onShowBtnPressed = async () => {
    updateInfo();
  };
  //update parts list
  const onClickParts = (part) => {
    console.log(partslist);
    var partslist_t = partslist;
    if (partslist_t[part] == 1) {
      partslist_t[part] = 0;
    } else {
      partslist_t[part] = 1;
    }
    setPartslist([...partslist_t])
  }
  const onUpgradeBtnPressed = async () => {
    let list = []
    for (var i = 0; i < partslist.length; i++) {
      if (partslist[i] == 1) {
        list.push(i)
      }
    }
    console.log(list)
    const {success, status } = await upgradeParts(amount, list)
  };
  const onApprovePressed = async () => {
    const {success, status } = await approveToken();
  }
//test item
  return (
    <div className="Minter">
      <div className="header">
        <div className = "header__logo">
          <a href="/">Loot::Cube</a>
        </div>
        <div className = "header__links">
          <ul><li>
            <a id="walletButton" onClick={connectWalletPressed}>
              {walletAddress.length > 0 ? (
                "Connected: " +
                String(walletAddress).substring(0, 6) +
                "..." +
                String(walletAddress).substring(38)
              ) : (
                "Connect Wallet"
              )}
            </a>
          </li><li>
            <a id="faqButton">
              FAQ
            </a>
          </li></ul>
        </div>
      </div>
      <div id="title">Loot::Cube</div>
      <Grid container className = "home__feature">
        <Grid item xs = {2} />
        <Grid item xs={8} className ="desc">Loot&Cube follows the item and affix system of Loot (Loot website), and derives a new function of replacing and
         washing equipment (the core function of Cube), which pays tribute to Loot, the great NFT invention and classic ARPG Diablo II
         </Grid>
         <Grid item xs = {2} />
      </Grid>
      <Grid container className = "home__feature">
        <Grid item xs={4} className = "home__function">
          <h2>Mint a cube</h2>
          <button id="mintButton" onClick={onMintBtnPressed}>
            MINT
          </button>
        </Grid>
        <Grid item xs={4} className = "home__function">
          <form>
            <h2>Show a minted cube</h2>
            <input
              type="text"
              value={amount}
              onChange={(event) => setAmount(event.target.value)}
            />
          </form>
          <button id="showButton" onClick={onShowBtnPressed}>
            SHOW
          </button>
        </Grid>
        {(approveState>0 || freePlayNum > 0) ?
        <Grid item xs={4} className = "home__function">
          <form>
            <h2>Upgrade items</h2>
            {freePlayNum > 0 ? <span>You have {freePlayNum} free upgrades</span> : ""}<br />
            <input
              type="text"
              value={amount}
              onChange={(event) => setAmount(event.target.value)}
            />
          </form>
          <ul>
            <li><a className={(partslist[0]==1)?"selected":""} onClick={() => onClickParts(0)}>Weapon</a></li>
            <li><a className={(partslist[1]==1)?"selected":""} onClick={() => onClickParts(1)}>Chest</a></li>
            <li><a className={(partslist[2]==1)?"selected":""} onClick={() => onClickParts(2)}>Head</a></li>
            <li><a className={(partslist[3]==1)?"selected":""} onClick={() => onClickParts(3)}>Waist</a></li>
          </ul>
          <ul>
            <li><a className={(partslist[4]==1)?"selected":""} onClick={() => onClickParts(4)}>Foot</a></li>
            <li><a className={(partslist[5]==1)?"selected":""} onClick={() => onClickParts(5)}>Hand</a></li>
            <li><a className={(partslist[6]==1)?"selected":""} onClick={() => onClickParts(6)}>Neck</a></li>
            <li><a className={(partslist[7]==1)?"selected":""} onClick={() => onClickParts(7)}>Ring</a></li>
          </ul>
          <button id="upgradeButton" onClick={onUpgradeBtnPressed}>
            UPGRADE
          </button>
        </Grid> : <Grid item xs={4} className = "home__function">
            <h2>Upgrade items</h2>
            <span>You need approve cube gov token before upgrade</span><br/>
          <button id="approveButton" onClick={onApprovePressed}>
            Approve
          </button>
        </Grid>}
      </Grid>
      <div className="home__feature">
        <span>Current Bag:</span>
        <a
            href={"https://testnets.opensea.io/assets/0xd4935AB96bA596689bEb2aB31E649e4E8d686b15/"+amount}
            target="_blank"
            rel="noopener noreferrer"
            key={name}
            className="bag"
          >
            <div className="bag_attributes">
              <span>{name}</span>
              <ul>
                {attributes.map((attribute, i) => (
                  <li className={(attribute.level > 2)?"sritem":""} key={i}>
                    {(attribute.suffix=="")?"":<span>[{attribute.suffix}]</span>}
                    <span>{attribute.base}</span>
                    <span>{(attribute.postfix!=""?" of ":"")}{attribute.postfix}</span>
                  </li>
                ))}
              </ul>
            </div>
          </a>
      </div>
      <p id="status" style={{ color: "red" }}>
        {status}
      </p>
    </div>
  );
};

export default Minter;
