// import { pinJSONToIPFS } from "./pinata.js";
// require("dotenv").config();
// const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
// const contractABI = require("../contract-abi.json");
// const contractAddress = "0x4C4a07F737Bf57F6632B6CAB089B78f62385aCaE";
import Web3 from "web3";
const contractABI = require("./contract-abi.json");
const govABI = require("./gov-abi.json")
const contractAddress = "0xd4935AB96bA596689bEb2aB31E649e4E8d686b15";
const govAddress = "0xe1fe139745bf06419aa637f005c72c3b80f8df7b"
const Web3Utils = require('web3-utils');
// const web3defualt = require('web3');
// const Web3Utils = web3defualt.utils;

// const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = new Web3(window.ethereum);
export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: ".",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

async function loadContract() {
  // console.log(contractABI, contractAddress)
  return new web3.eth.Contract(contractABI, contractAddress);
}

export const getTokenURI = async (sn) => {
    if (!window.contract) {
      window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    }
    var tokenURI  = await window.contract.methods.tokenURI(sn).call();
    // console.log(tokenURI);
    return tokenURI;
}

export const getFreePlayNum = async (sn) => {
  if (!window.contract) {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  }
  console.log(window.contract)
  var tidToPlayNum  = await window.contract.methods.tidToPlayNum(sn).call();
  console.log(tidToPlayNum);
  return tidToPlayNum;
}

export const getApproveState = async () => {
  if (!window.govcontract) {
    window.govcontract = await new web3.eth.Contract(govABI, govAddress);
  }
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      // console.log(addressArray[0],contractAddress);
      // console.log(window.govcontract)
      var approveState  = await window.govcontract.methods.allowance(addressArray[0], contractAddress).call();
      // var approveState  = await window.govcontract.methods.balanceOf(addressArray[0]).call();
      // console.log(Web3Utils.fromWei(approveState, "ether"));
      return approveState;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  }
}

export const approveToken = async () => {
  if (!window.govcontract) {
    window.govcontract = await new web3.eth.Contract(govABI, govAddress);
  }
  const transactionParameters = {
    to: govAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    value: 0,
    data: window.govcontract.methods
      .approve(contractAddress, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
      .encodeABI(),
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        "View transaction on Etherscan: https://rinkeby.etherscan.io/tx/" +
        txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
}

export const mintNFT = async () => {
  if (!window.contract) {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  }
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    value: 0,
    data: window.contract.methods
      .claim()
      .encodeABI(),
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        "View transaction on Etherscan: https://rinkeby.etherscan.io/tx/" +
        txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
}

export const upgradeParts = async (sn, parts) => {
  if (sn.trim() == "") {
    return {
      success: false,
      status: "❗Amount cant be empty"
    };
  }

  sn = parseInt(sn)

  if (!window.contract) {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  }
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    value: 0,
    data: window.contract.methods
      .upgradeParts(sn, parts)
      .encodeABI(),
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        "View transaction on Etherscan: https://rinkeby.etherscan.io/tx/" +
        txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }

}
